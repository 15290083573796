import Moment from "moment";
import ModalSearchSavingAccount from "../../../components/modal-search-saving-account";
import Mixins from "@/service/MixinsFuntions.js";

export default {
  name: "TSavingAccountGenerateHeaderBook",
  components: {
    ModalSearchSavingAccount,
  },
  mixins: [Mixins],
  data() {
    return {
      validationDomain: "",
      property: {
        modal: {
          showModalCheckingSavingAccount: false,
        },
      },
      dataForm: {
        format: "new",
      },
      headerData: {
        accountNumber: "",
        cifName: "",
        cifAddress: "",
        cifIdCardNo: "",
        officeName: "",
        cifNumber: "",
        printDate: "",
        savingCode: "",
        savingName: "",
        cetakBukuLama: false,
      },
    };
  },
  methods: {
    clearPaperHeaderBook() {
      this.headerData.accountNumber = "";
      this.headerData.cifName = "";
      this.headerData.cifAddress = "";
      this.headerData.cifIdCardNo = "";
      this.headerData.officeName = "";
      this.headerData.cifNumber = "";
      this.headerData.printDate = "";
      this.headerData.savingCode = "";
      this.headerData.savingName = "";
    },
    async getSavingAccountGenerateHeaderBook(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.dialog.alert(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.clearPaperHeaderBook();
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "transaction",
            reqUrl:
              "saving-account/generate-header-book/" + props.row.accountNumber,
          });
          if (resp.data.code === "SUCCESS") {
            this.headerData.accountNumber =
              resp.data.data.accountNumber.length === 10
                ? this.maskAccountNumber(
                    resp.data.data.accountNumber,
                    "###-##-#####"
                  )
                : resp.data.data.accountNumber;
            this.headerData.savingName = resp.data.data.savingName;
            this.headerData.savingCode = resp.data.data.savingCode;
            this.headerData.cifName = `${resp.data.data.cifName} ${
              resp.data.data.qqCode
                ? `${resp.data.data.qqCode} ${resp.data.data.qqName}`
                : ""
            }`;
            this.headerData.cifAddress = resp.data.data.cifAddress;
            this.headerData.cifIdCardNo = resp.data.data.cifIdCardNo;
            this.headerData.officeName = resp.data.data.officeName;
            this.property.modal.showModalCheckingSavingAccount = false;
            this.headerData.cifNumber = resp.data.data.cifNumber;
            this.headerData.printDate = Moment(new Date()).format("DD-MM-YYYY");
          } else if (resp.data.code === "SAVING_ACCOUNT_NOT_FOUND") {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: `Nomor Rekening Tidak Tersedia `,
              confirmText: "Ok",
              type: "is-danger",
            });
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        } catch (error) {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      }
    },
    maskAccountNumber(value, pattern) {
      console.log(pattern);
      var i = 0,
        v = value.toString();
      return pattern.replace(/#/g, (_) => v[i++]);
    },
    printHeaderBook() {
      switch (this.dataForm.format) {
        case "old":
          this.printHeaderBookOld();
          break;
        case "new":
          this.printHeaderBookNew();
          break;
        default:
          return;
          break;
      }
    },
  },
  mounted() {
    this.validationDomain = window.location.hostname;
    Moment.locale("id");
  },
};
